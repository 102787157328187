import React from 'react';
import styled from 'styled-components';
import ContactCard from '$components/ContactCard';
import { WrapLarge } from '$components/Wraps';
import FadeInSection from '$components/FadeInSection';
import { BlockCoworkersProps } from './types';
import Title from './Title';

const Coworkers = styled(WrapLarge)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 3.611111111vw 7.222222222vw;
  margin-bottom: 5rem;

  @media screen and (min-width: 978px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 6.5rem 6.5rem;
  }
`;

const BlockCoworkers: React.FC<BlockCoworkersProps> = ({ coworkers, heading }) => (
  <FadeInSection>
    <Title>{heading}</Title>
    <Coworkers>
      {coworkers.map(({ coworker, BackgroundColor }) => (
        <ContactCard
          key={coworker.Name}
          image={coworker.Image}
          name={coworker.Name}
          role={coworker.Role}
          phone={coworker.Phone}
          email={coworker.Email}
          backgroundColor={BackgroundColor}
        />
      ))}
    </Coworkers>
  </FadeInSection>
);

export default BlockCoworkers;
