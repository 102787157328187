import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2`
  color: var(--color-slate);
  font-size: 2em;
  text-align: center;
  margin: 0 0 3rem;
`;

const Title: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default Title;
